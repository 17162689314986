import React from 'react';
import { Switch } from '@rebass/forms';
import { Flex } from 'rebass';
import { useToasts } from 'react-toast-notifications';
import classNames from 'classnames';

import studyService from 'services/studies';
import useMutation from 'hooks/useMutation';
import { FiLink2 } from 'react-icons/all';

/**
 * A switch component that toggles a user's free-busy status in a study.
 *
 * @param {string} userId - The ID of the user
 * @param {boolean} isFreeBusy - The current free-busy status of the user
 * @param {string} studyId - The ID of the study
 * @param {Function} onUpdate - A callback function to be called after the free-busy status is updated
 * @param {boolean} isConnected - Whether the user is connected to the external calendar
 * @param {React.CSSProperties} [style] - The style object to be applied to the component
 * @param {string} [className] - The class name to be applied to the component
 */
export function FreeBusySwitch({ userId, isFreeBusy, studyId, onUpdate, isConnected, style, className }) {
    const { addToast } = useToasts();
    const { isLoading, mutate: onFreeBusyChange } = useMutation({
        mutationFn: () => studyService.studyUpdateUser({ studyId, userId, data: { freebusy: !isFreeBusy } }),
        onSuccess: () => {
            if (onUpdate && typeof onUpdate === 'function') onUpdate();
        },
        onError: (_, errorText) => {
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });

    if (!isConnected)
        return (
            <Flex alignItems="center" style={style} className={classNames('color-text-secondary', className)}>
                <p className="fs-accent-12" style={{ margin: 0 }}>
                    Not connected
                </p>
            </Flex>
        );

    return (
        <Flex alignItems="center" style={style} className={className}>
            <Switch
                mr="6px"
                disabled={!isConnected || isLoading}
                className="switch-small"
                checked={isConnected && isFreeBusy}
                onClick={onFreeBusyChange}
            />
            <p className="fs-accent-12 color-text-primary" style={{ margin: 0 }}>
                Respect meetings
            </p>
        </Flex>
    );
}
