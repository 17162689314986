import React from 'react';
import { Box } from 'rebass';
import { FiEye } from 'react-icons/fi';

const PublicScreenPagePreviewIndicator = () => (
    <div className="no-print preview-mode">
        <p className="fs-title-14 color-light" style={{ margin: '0px', marginBottom: '6px' }}>
            <FiEye style={{ fontSize: '20px', verticalAlign: 'bottom' }} /> Preview mode
        </p>
        <Box className="fs-body-12 color-light-70">
            This is a preview, so your submission will not be saved. Do not share this link with participants.
        </Box>
    </div>
);

export default PublicScreenPagePreviewIndicator;
