exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".container__1r-54 {\n  position: relative;\n  max-width: 800px;\n  padding: 40px 40px 24px;\n  border-radius: 8px;\n  border: 1px solid #FFA800;\n  background: #fff;\n  box-shadow: 0 10px 24px 0 #33404D0A;\n}\n\n.lock__1AjNB {\n  position: absolute;\n  background: #FFA800;\n  color: #fff;\n  font-size: 12px;\n  line-height: 16px;\n  font-weight: 600;\n  border-radius: 0 0 8px 8px;\n  padding: 4px 6px;\n  left: 40px;\n  top: 0;\n}\n\n.lock__1AjNB svg {\n  font-size: 16px;\n  vertical-align: bottom;\n  margin-right: 4px;\n}\n\n.badge__1wGls {\n  height: 24px;\n  padding: 4px 8px;\n  background: #F6F6F6;\n  border-radius: 4px;\n  font-size: 12px;\n  line-height: 16px;\n  color: #606E85;\n}\n\n.badgePrefix__3ndOW {\n  font-weight: 500;\n  margin-right: 4px;\n}\n\n.badge__1wGls svg {\n  font-size: 16px;\n  vertical-align: bottom;\n  margin-right: 4px;\n}\n", ""]);

// Exports
exports.locals = {
	"container": "container__1r-54",
	"lock": "lock__1AjNB",
	"badge": "badge__1wGls",
	"badgePrefix": "badgePrefix__3ndOW"
};