import React from 'react';
import { createPortal } from 'react-dom';

/**
 * Portal component. It renders the children in the body of the document.
 * Useful for modals, dropdowns, etc.
 *
 * @param {React.ReactNode} children
 * @param {boolean} [isOpen] whether the portal is open
 */
export function Portal({ children, isOpen = true }) {
    if (!isOpen) return null;

    return createPortal(children, document.body);
}

export default Portal;
