import React from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';

const BadgeContainer = styled.span`
    padding: 4px 8px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 19px;
    min-height: 28px;
    font-weight: 500;
    border-radius: 4px;
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;

    ${({ size }) =>
        size === 'sm' &&
        css`
            padding: 2px 6px;
            line-height: 16px;
            font-size: 12px;
            min-height: 20px;
        `}

    svg {
        vertical-align: top;
        margin: 2px 6px 0 2px;
        padding: 0;
        font-size: 16px;

        ${({ size }) =>
            size === 'sm' &&
            css`
                margin: 1px 4px 0 1px;
                font-size: 12px;
            `}
    }
`;

/**
 * Badge component
 *
 * @param {Object} props props
 * @param {string} className className
 * @param {'md' | 'sm'} [size] size
 * @param {'main' | 'danger' | 'success' | 'warn' | 'info' | 'pure'} type color type
 * @param {React.CSSProperties} props.[style] style
 * @returns {React.ReactNode} Badge component
 */
export const Badge = ({ className, type, size = 'md', ...props }) => {
    return <BadgeContainer className={classNames(`bg-${type} text-${type}`, className)} size={size} {...props} />;
};

export default Badge;
