import { useToasts } from 'react-toast-notifications';

/**
 * Hook to show toasts. It's a wrapper around the react-toast-notifications library.
 * This approach is useful to reduce the boilerplate code when showing toasts.
 */
export const useToastHelper = () => {
    const { addToast } = useToasts();

    /**
     * Show a success toast.
     *
     * @param {React.ReactNode} message Message to be shown in the toast
     * @param {Object} [options] Options to customize the toast
     */
    const addSuccessToast = (message, options) => {
        addToast(message, {
            appearance: 'success',
            autoDismiss: true,
            ...options
        });
    };

    /**
     * Show an error toast.
     *
     * @param {React.ReactNode} message Message to be shown in the toast
     * @param {Object} [options] Options to customize the toast
     */
    const addErrorToast = (message, options) => {
        addToast(message, {
            appearance: 'error',
            autoDismiss: true,
            ...options
        });
    };

    /**
     * Show an info toast.
     *
     * @param {React.ReactNode} message Message to be shown in the toast
     * @param {Object} [options] Options to customize the toast
     */
    const addInfoToast = (message, options) => {
        addToast(message, {
            appearance: 'info',
            autoDismiss: true,
            ...options
        });
    };

    /**
     * Show a warning toast.
     *
     * @param {React.ReactNode} message Message to be shown in the toast
     * @param {Object} [options] Options to customize the toast
     */
    const addWarningToast = (message, options) => {
        addToast(message, {
            appearance: 'warning',
            autoDismiss: true,
            ...options
        });
    };

    return {
        addSuccessToast,
        addErrorToast,
        addInfoToast,
        addWarningToast
    };
};
