import React, { useMemo, useState, useRef, useEffect } from 'react';
import { Box, Button, Flex } from 'rebass';
import { Input, Label, Radio } from '@rebass/forms';
import { FiX, FiPlus, FiTrash2, FiUserPlus } from 'react-icons/fi';

import NiceModal from 'components/NiceModal';
import { Portal } from 'components/Portal';
import useQuery from 'hooks/useQuery';
import { Table } from 'components/Table';
import { FreeBusySwitch } from 'components/FreeBusySwitch';
import serviceStudies from 'services/studies';
import { useMutation } from 'hooks/useMutation';
import services from 'services/services';
import NiceDropdown from 'components/NiceDropdown';
import { PositionerV2 } from 'components/Positioner';
import { Badge } from 'components/Badge';
import { produce } from 'immer';
import InformationTooltip from 'components/InformationTooltip';
import { Skeleton } from 'components/Skeleton';
import { useToastHelper } from 'hooks/useToastHelper';

export function AddCollaboratorDropdown({ studyId, onUpdate }) {
    const { addSuccessToast, addErrorToast } = useToastHelper();
    const { mutate: addCollaborator } = useMutation({
        mutationFn: accountUserId => serviceStudies.studyAddUser(studyId, accountUserId),
        onSuccess: collaborators => {
            onUpdate && onUpdate(collaborators);
            addSuccessToast('Collaborator added');
        },
        onError: (_, errorText) => {
            addErrorToast(errorText);
        }
    });
    const { data: accounts } = useQuery({
        queryFn: () => services.getAccountUsers(),
        onError: (_, errorText) => {
            addErrorToast(errorText);
        }
    });
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const items = useMemo(() => {
        if (!accounts) return [];

        return accounts.map(account => ({
            title: account.user.name,
            id: account.user.id
        }));
    }, [accounts]);
    const buttonRef = useRef();

    return (
        <>
            <Button ref={buttonRef} type="button" variant="secondary" onClick={() => setIsDropdownOpen(true)}>
                <FiPlus /> Add Collaborator
            </Button>
            <Portal isOpen={isDropdownOpen}>
                <PositionerV2 anchorRef={buttonRef}>
                    <NiceDropdown
                        style={{ inset: 'auto', position: 'static' }}
                        showSearch
                        onClose={() => setIsDropdownOpen(false)}
                        items={items}
                        onChange={userId => addCollaborator(userId)}
                        renderBottomStickyButton={
                            <Button
                                type="button"
                                variant="secondary"
                                onClick={() => {
                                    window.location = '/settings/users';
                                }}
                            >
                                <FiUserPlus /> Invite users
                            </Button>
                        }
                    />
                </PositionerV2>
            </Portal>
        </>
    );
}

export function GoogleCalendarIcon() {
    return (
        <img
            width="24px"
            height="24px"
            src="/google-calendar.png"
            alt="google-calendar-icon"
            style={{
                objectFit: 'contain',
                width: '24px',
                height: '24px'
            }}
        />
    );
}

export function MicrosoftCalendarIcon() {
    return (
        <img
            width="24px"
            height="24px"
            src="/outlook-calendar.png"
            alt="google-calendar-icon"
            style={{
                objectFit: 'contain',
                width: '24px',
                height: '24px',
                padding: '3px'
            }}
        />
    );
}

/**
 * StudyCollaborators component
 *
 * @param {number | string} studyId - The ID of the study
 * @param {React.CSSProperties} [style] - The style object to be applied to the component
 * @param {string} [className] - The class name to be applied to the component
 * @param {(collaborators: any) => void} [onUpdate] - The callback to be called when collaborators are updated
 */
export function StudyCollaborators({ studyId, style, className, onUpdate }) {
    const { addSuccessToast, addErrorToast } = useToastHelper();
    const { isLoading, data: collaborators, error, called, optimisticUpdate } = useQuery({
        queryFn: () => serviceStudies.studyGetUsers(studyId),
        variables: {
            studyId
        },
        onError: (_, errorText) => {
            addErrorToast(errorText);
        }
    });
    const { mutate: removeCollaborator, isLoading: isRemoving } = useMutation({
        mutationFn: userId => serviceStudies.studyRemoveUser(studyId, userId),
        onSuccess: async () => {
            addSuccessToast('Collaborator removed');
        },
        onError: (_, errorText) => {
            addErrorToast(errorText);
        }
    });
    const { isLoading: isChangingOrganizer, mutate: switchOrganizer } = useMutation({
        mutationFn: ({ collaboratorId }) => serviceStudies.switchOrganizer(studyId, collaboratorId),
        onError: (_, errorText) => {
            addErrorToast(errorText);
        }
    });

    const onSwitchOrganizer = async collaboratorId => {
        await switchOrganizer(
            { collaboratorId },
            {
                onSuccess: () => {
                    updateCollaborators(
                        produce(collaborators, draft => {
                            draft.forEach(collaboratorDraft => {
                                collaboratorDraft.is_organizer = collaboratorDraft.id === collaboratorId;
                            });
                        })
                    );
                }
            }
        );
    };

    const updateCollaborators = newCollaborators => {
        optimisticUpdate(() => newCollaborators);
        onUpdate && onUpdate(newCollaborators);
    };

    const onFreeBusyUpdate = async collaboratorUserId => {
        const newCollaborators = produce(collaborators, draft => {
            const collaborator = draft.find(collaborator => collaborator.user.id === collaboratorUserId);
            collaborator.freebusy = !collaborator.freebusy;
        });
        updateCollaborators(newCollaborators);
    };

    const onCollaboratorRemove = async collaboratorUserId => {
        await removeCollaborator(collaboratorUserId, {
            onSuccess: () => {
                updateCollaborators(collaborators.filter(collaborator => collaborator.user.id !== collaboratorUserId));
            }
        });
    };

    if (error) return <p>Error: {error}</p>;

    if (isLoading && !called) return <Skeleton height="160px" style={style} />;

    return (
        <Box style={style} className={className}>
            <h3 className="fs-title-16" style={{ marginBottom: '12px' }}>
                Collaborators{' '}
                <a href="#" data-beacon-article="62fadf1761ff5d5f24f9c1ae" className="help-question">
                    ?
                </a>
            </h3>
            {collaborators.length > 0 && (
                <Table style={{ width: '100%', overflow: 'auto', marginBottom: '12px' }} size="sm">
                    <Table.Head>
                        <Table.Tr>
                            <Table.Th style={{ paddingLeft: 0 }}>Account name</Table.Th>
                            <Table.Th width="210px">
                                Calendar Availability{' '}
                                <InformationTooltip style={{ marginLeft: '4px' }} id="calendar-free-busy-info">
                                    The scheduling experience in this study will show or hide available sessions based
                                    on these calendar settings.
                                    <br />
                                    <br />
                                    <b>On:</b> Sessions will be hidden if they overlap with a busy time on a
                                    collaborator's calendar.
                                    <br />
                                    <b>Off:</b> Sessions will NOT be hidden if they overlap with a busy time on a
                                    collaborator's calendar.
                                </InformationTooltip>
                            </Table.Th>
                            <Table.Th>
                                Organizer{' '}
                                <InformationTooltip style={{ marginLeft: '4px' }} id="organizer-tooltip">
                                    When your collaborators have Google Calendar or Microsoft Calendar integrated, the
                                    Organizer is used as the primary calendar for creating events.
                                </InformationTooltip>
                            </Table.Th>
                            <Table.Th style={{ paddingRight: 0 }}></Table.Th>
                        </Table.Tr>
                    </Table.Head>
                    <Table.Body>
                        {collaborators.map(collaborator => (
                            <Table.Tr key={collaborator.id}>
                                <Table.Td style={{ paddingLeft: 0 }} className="fs-accent-14 color-text-primary">
                                    <span style={{ marginRight: '8px' }}>{collaborator.user.name}</span>
                                </Table.Td>
                                <Table.Td>
                                    <Flex alignItems="center" style={{ gap: '10px' }}>
                                        {collaborator.user.google_calendar === 1 && <GoogleCalendarIcon />}
                                        {collaborator.user.microsoft_calendar === 1 && <MicrosoftCalendarIcon />}
                                        <FreeBusySwitch
                                            userId={collaborator.user.id}
                                            isFreeBusy={!!collaborator.freebusy}
                                            studyId={studyId}
                                            onUpdate={() => onFreeBusyUpdate(collaborator.user.id)}
                                            isConnected={
                                                collaborator.user.google_calendar !== 0 ||
                                                collaborator.user.microsoft_calendar !== 0
                                            }
                                        />
                                    </Flex>
                                </Table.Td>
                                <Table.Td>
                                    <Label className="pointer">
                                        <Radio
                                            onChange={() => onSwitchOrganizer(collaborator.id)}
                                            name="organizer"
                                            value={collaborator.id}
                                            disabled={isChangingOrganizer}
                                            checked={collaborator.is_organizer}
                                        />
                                    </Label>
                                </Table.Td>
                                <Table.Td style={{ paddingRight: 0, textAlign: 'right' }}>
                                    <Button
                                        disabled={isRemoving}
                                        type="button"
                                        variant="secondary-gray"
                                        className="secondary-icon"
                                        onClick={() => onCollaboratorRemove(collaborator.user.id)}
                                    >
                                        <FiTrash2 />
                                    </Button>
                                </Table.Td>
                            </Table.Tr>
                        ))}
                    </Table.Body>
                </Table>
            )}
            {collaborators.length === 0 && <p className="fs-accent-14 color-text-secondary">No collaborators</p>}
            <AddCollaboratorDropdown studyId={studyId} onUpdate={updateCollaborators} />
        </Box>
    );
}

export function AddObserverInput({ studyId, onUpdate }) {
    /**
     * @type {React.MutableRefObject<HTMLInputElement>}
     */
    const refInput = useRef();
    const { addErrorToast } = useToastHelper();
    const [isActive, setIsActive] = useState(false);
    const { mutate: addObserver, isLoading: isAdding } = useMutation({
        mutationFn: observer => serviceStudies.addObserver(studyId, observer),
        onSuccess: observers => {
            refInput.current.value = '';
            onUpdate && onUpdate(observers);
        },
        onError: (_, errorText) => {
            addErrorToast(errorText);
        }
    });

    const invite = async () => {
        await addObserver(refInput.current.value);
    };

    /**
     * Handle the enter key press event
     *
     * @param {React.KeyboardEvent<HTMLInputElement>} event
     * @returns {Promise<void>}
     */
    const handleEnter = async event => {
        if (event.key === 'Enter' && !event.repeat) {
            await invite();

            // We need to bring the focus back to the input
            refInput.current.focus();
        }
    };

    // Active the input when the button is clicked
    // We use useEffect to
    useEffect(() => {
        if (isActive) {
            refInput.current.focus();
        }
    }, [isActive]);

    if (!isActive)
        return (
            <Button type="button" variant="secondary" onClick={() => setIsActive(true)}>
                <FiPlus /> Add Observer
            </Button>
        );

    return (
        <Flex style={{ gap: '8px' }}>
            <Input
                flexGrow={1}
                placeholder="Enter email address"
                disabled={isAdding}
                ref={refInput}
                onKeyDown={handleEnter}
            />
            <Button type="button" disabled={isAdding} style={{ margin: 0, minWidth: 'max-content' }} onClick={invite}>
                Invite
            </Button>
            <Button
                type="button"
                disabled={isAdding}
                variant="transparent-icon"
                style={{ margin: 0, minWidth: 'max-content' }}
                onClick={() => setIsActive(false)}
            >
                <FiX />
            </Button>
        </Flex>
    );
}

export function StudyObservers({ studyId, style, className, onUpdate }) {
    const { addSuccessToast, addErrorToast } = useToastHelper();
    const { isLoading, data: observers, error, optimisticUpdate } = useQuery({
        queryFn: () => serviceStudies.getObservers(studyId),
        onError: (_, errorText) => {
            addErrorToast(errorText);
        }
    });
    const { isLoading: isRemoving, mutate: removeObserver } = useMutation({
        mutationFn: observer => serviceStudies.removeObserver(studyId, observer),
        onSuccess: observers => {
            updateObservers(observers);
            addSuccessToast('Observer removed');
        },
        onError: (_, errorText) => {
            addErrorToast(errorText);
        }
    });

    const updateObservers = observers => {
        optimisticUpdate(() => observers);
        onUpdate && onUpdate(observers);
    };

    if (error) return <p>Error: {error}</p>;

    if (isLoading) return <Skeleton height="120px" style={style} />;

    return (
        <Box style={style} className={className}>
            <h3 className="fs-title-16" style={{ marginBottom: '12px' }}>
                Observers{' '}
                <a href="#" data-beacon-article="62fadefa61ff5d5f24f9c1ad" className="help-question">
                    ?
                </a>
            </h3>
            {observers.length > 0 && (
                <Table style={{ width: '100%', overflow: 'auto', marginBottom: '12px' }} size="sm">
                    <Table.Body>
                        {observers.map(observer => (
                            <Table.Tr key={observer}>
                                <Table.Td style={{ paddingLeft: 0 }} className="fs-accent-14 color-text-primary">
                                    {observer}
                                </Table.Td>
                                <Table.Td style={{ paddingRight: 0, textAlign: 'right' }}>
                                    <Button
                                        disabled={isRemoving}
                                        type="button"
                                        variant="secondary-gray"
                                        className="secondary-icon"
                                        onClick={() => removeObserver(observer)}
                                    >
                                        <FiTrash2 />
                                    </Button>
                                </Table.Td>
                            </Table.Tr>
                        ))}
                    </Table.Body>
                </Table>
            )}
            {observers.length === 0 && <p className="fs-accent-14 color-text-secondary">No observers</p>}
            <AddObserverInput studyId={studyId} onUpdate={updateObservers} />
        </Box>
    );
}

export function ManageStudyMembers({ studyId, onObserversUpdate, onCollaboratorsUpdate }) {
    return (
        <div>
            <StudyCollaborators studyId={studyId} style={{ marginBottom: '24px' }} onUpdate={onCollaboratorsUpdate} />
            <StudyObservers studyId={studyId} onUpdate={onObserversUpdate} />
        </div>
    );
}

const withModal = Component => ({ isOpen, onClose, ...props }) => {
    return (
        <Portal>
            <NiceModal
                style={{ content: { width: '660px', maxWidth: 'calc(100vw - 64px)' } }}
                isOpen={isOpen}
                onRequestClose={onClose}
                title="Manage Study Members"
            >
                <Component {...props} />
            </NiceModal>
        </Portal>
    );
};

export const ManageStudyMembersModal = withModal(ManageStudyMembers);
