import React, { useMemo } from 'react';
import { Box, Button, Flex } from 'rebass';
import classNames from 'classnames';
import { FiClock, FiFileText, FiGlobe, FiLock, FiPrinter } from 'react-icons/fi';

import moment from 'utils/moment';

import styles from './styles.module.css';

export const PRINTABLE_PAGE = Object.freeze({
    ON: 'on',
    OFF: 'off'
});

/**
 * Display badge with information about the respondent. For example, IP address or answered at.
 *
 * @param {React.ReactNode} icon
 * @param {string} prefix
 * @param {string} value
 */
const RespondentBadge = ({ icon, prefix, value }) => (
    <Box className={styles.badge}>
        {icon}
        <span className={styles.badgePrefix}>{prefix}:</span>
        {value}
    </Box>
);

/**
 * Display admin tools for the screener page.
 *
 * @param {boolean} [isPrintable] Indicates if the page is in printable mode
 * @param {boolean} [isSwitch] Indicates if the printable switch is visible
 * @param {string} [ip] IP address of the respondent
 * @param {Date | string | number} [answeredAt] Date and time the screener was answered
 * @param {string} [timezone] Timezone of the current user
 * @param {React.CSSProperties} [style]
 * @param {string} [className]
 * @param {(next: string) => void} [onPrintableSwitch] Callback to switch between printable and default view. Only called when isSwitch is true
 */
export function ScreenerAdminTools({
    className,
    style,
    isPrintable,
    onPrintableSwitch,
    isSwitch,
    ip,
    answeredAt,
    timezone
}) {
    const isRespondentInfo = !!(ip || answeredAt);
    const answeredAtValue = useMemo(() => {
        let value = moment(answeredAt);

        if (timezone) {
            value = value.tz(timezone);
        }

        return value.format('YYYY-MM-DD HH:mm:ss z');
    }, [answeredAt, timezone]);

    return (
        <Box style={style} className={classNames('no-print', styles.container, className)}>
            <div className={styles.lock}>
                <FiLock />
                Only visible to you
            </div>
            <Flex alignItems="center" className="gap-4" flexWrap="wrap" style={{ marginBottom: '16px' }}>
                <h1 className="fs-headline-20 m-0 grow">Admin tools</h1>
                {isPrintable && isSwitch && (
                    <Button
                        type="button"
                        variant="transparent"
                        onClick={() => onPrintableSwitch(PRINTABLE_PAGE.OFF)}
                        className="m-0"
                    >
                        Back to default view
                    </Button>
                )}
                {isPrintable && (
                    <Button type="button" variant="secondary-gray" onClick={window.print} className="m-0">
                        <FiPrinter /> Print
                    </Button>
                )}
                {!isPrintable && (
                    <Button
                        type="button"
                        variant="secondary-gray"
                        onClick={() => onPrintableSwitch(PRINTABLE_PAGE.ON)}
                        className="m-0"
                    >
                        <FiFileText /> View Printable Page
                    </Button>
                )}
            </Flex>
            {isRespondentInfo && (
                <>
                    <p className="m-0 fs-accent-12 color-text-secondary" style={{ marginBottom: '8px' }}>
                        Respondent Information
                    </p>
                    <Flex className="gap-2" flexWrap="wrap">
                        {ip && <RespondentBadge icon={<FiGlobe />} prefix="IP Address" value={ip} />}
                        {answeredAt && (
                            <RespondentBadge icon={<FiClock />} prefix="Answered at" value={answeredAtValue} />
                        )}
                    </Flex>
                </>
            )}
        </Box>
    );
}
