import React from 'react';
import { Box, Button } from 'rebass';
import { FiSend } from 'react-icons/fi';

import ActionPopup from 'components/ActionPopup';
import services from 'services/services';
import Portal from 'components/Portal';
import useMutation from 'hooks/useMutation';
import { useToastHelper } from 'hooks/useToastHelper';

/**
 * @typedef {'zoomOn' | 'zoomOff' | null} PopupMode
 */

/**
 * @param {PopupMode} mode
 */
const UpdateText = ({ mode }) => {
    if (mode === 'zoomOn')
        return (
            <>Location updated to Zoom. Would you like us to create meetings and send updates to future participants?</>
        );

    if (mode === 'zoomOff')
        return (
            <>
                Zoom location removed. Would you like us to delete the meetings in your Zoom account and send updates to
                future participants?
            </>
        );

    return (
        <>
            A new scheduling change was saved, and this study has respondents scheduled in the future. Would you like to
            send updates to the calendar events?
        </>
    );
};

/**
 * @param {PopupMode} mode
 */
const SendUpdateButtonText = ({ mode }) => {
    if (mode === 'zoomOn') return <>Create Zoom meetings and send updates</>;

    if (mode === 'zoomOff') return <>Delete Zoom meetings and send updates</>;

    return <>Send updates</>;
};

/**
 * Component that asks the user if they want to send updates to the calendar events when a study is updated.
 *
 * @param {() => void} onClose
 * @param {string | number} studyId
 * @param {boolean} isOpen
 * @param {PopupMode} mode
 */
export function StudyUpdatedPopup({ onClose, studyId, isOpen, mode = null }) {
    const { addSuccessToast, addErrorToast } = useToastHelper();
    const { isLoading: isSendingUpdates, mutate: sendStudySessionUpdates } = useMutation({
        mutationFn: () => {
            addSuccessToast('Starting to send session updates');
            return services.sendStudySessionUpdates(studyId);
        },
        onSuccess: () => {
            addSuccessToast('Successfully sent session updates');
            onClose();
        },
        onError: (_, errorText) => {
            addErrorToast(errorText);
        }
    });
    const { isLoading: isCreatingZoom, mutate: createZoomMeetingsAndSendSessionUpdates } = useMutation({
        mutationFn: () => {
            addSuccessToast('Starting to send session updates');
            return services.createZoomMeetingsAndSendSessionUpdates(studyId);
        },
        onSuccess: () => {
            addSuccessToast('Successfully sent session updates');
            onClose();
        },
        onError: (_, errorText) => {
            addErrorToast(errorText);
        }
    });
    const { isLoading: isDeletingZoom, mutate: deleteZoomMeetingsAndSendSessionUpdates } = useMutation({
        mutationFn: () => {
            addSuccessToast('Starting to send session updates');
            return services.deleteZoomMeetingsAndSendSessionUpdates(studyId);
        },
        onSuccess: () => {
            addSuccessToast('Successfully sent session updates');
            onClose();
        },
        onError: (_, errorText) => {
            addErrorToast(errorText);
        }
    });
    const isSending = isSendingUpdates || isCreatingZoom || isDeletingZoom;

    const dismissPopup = () => {
        if (mode && !confirm('Are you sure? Participants will not know about this change.')) return;

        onClose();
    };

    const updateSessions = () => {
        if (mode === 'zoomOn') return createZoomMeetingsAndSendSessionUpdates();

        if (mode === 'zoomOff') return deleteZoomMeetingsAndSendSessionUpdates();

        return sendStudySessionUpdates();
    };

    return (
        <Portal isOpen={isOpen}>
            <ActionPopup version="danger">
                <Box>
                    <UpdateText mode={mode} />
                </Box>

                <Box mt={3} style={{ textAlign: 'right' }}>
                    <Button variant="secondary-gray" disabled={isSending} onClick={dismissPopup}>
                        Do not send updates
                    </Button>
                    <Button ml={3} variant="primary" disabled={isSending} onClick={updateSessions}>
                        <FiSend /> <SendUpdateButtonText mode={mode} />
                    </Button>
                </Box>
            </ActionPopup>
        </Portal>
    );
}
