import React from 'react';
import { Box } from 'rebass';
import classNames from 'classnames';

/**
 * A skeleton component.
 *
 * @param {string|number} height - The height of the skeleton.
 * @param {string} className
 * @param {React.CSSProperties} style
 */
export function Skeleton({ height, className, style }) {
    return <Box className={classNames('skeleton', className)} height={height} style={style} />;
}
