/**
 * Documentation:
 *
 * `useMutation` is a custom hook tailored to streamline the process of making asynchronous
 * mutations, such as data updates or deletions, while adeptly managing the associated loading,
 * result, and error states. With this hook, developers can effortlessly execute a mutation
 * using a given mutation function and get a uniform interface for observing the loading state,
 * received data, and any emerging errors.
 *
 * This hook's strength lies in its versatility. One can control the mutation's execution,
 * pass varied inputs, and also embed into successful or faulty outcomes via callback functions.
 *
 * Internally, the hook oversees the loading state and systematically tracks errors, diminishing
 * the overhead of writing such routines by hand.
 *
 * @example
 * const myMutationFunction = async (input) => {
 *   const response = await fetch('https://api.example.com/resource', {
 *     method: 'POST',
 *     body: JSON.stringify(input),
 *   });
 *   if (!response.ok) throw new Error('Mutation failed');
 *   return response.json();
 * };
 *
 * const MyComponent = () => {
 *   const { mutate, isLoading, data, error } = useMutation({
 *     mutationFn: myMutationFunction,
 *     onSuccess: (data) => {
 *       console.log('Mutation successful:', data);
 *     },
 *     onError: (error) => {
 *       console.error('Mutation error:', error);
 *     },
 *   });
 *
 *   return (
 *     <div>
 *       <button onClick={() => mutate({ name: 'John' })}>
 *         {isLoading ? 'Loading...' : 'Submit'}
 *       </button>
 *       {data && <SuccessComponent data={data} />}
 *       {error && <ErrorComponent error={error} />
 *     </div>
 *   );
 * }
 *
 * Note: Ensure that `myMutationFunction` is a function that takes the input object
 * as its sole argument and returns a promise that resolves with the data or an error.
 */

import React, { useState } from 'react';

import services from 'services/services';

/**
 * Custom hook for mutations
 *
 * @param {Object} props
 * @param {Function} props.mutationFn mutation function
 * @param {Function} [props.onSuccess] success callback
 * @param {Function} [props.onError] error callback
 */
export const useMutation = ({ mutationFn, onSuccess, onError }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState(null);

    /**
     * Mutate function
     *
     * @param {any} [input] mutation input
     * @param {Object} [options] mutation options
     * @param {((data: any) => Promise<any>) | ((data: any) => any)} options.[onSuccess] success callback
     * @param {((error: Error, errorText: string) => Promise<any>) | ((error: Error, errorText: string) => any)} options.[onError] error callback
     */
    const mutate = async (input, options) => {
        setIsLoading(true);

        try {
            const responseData = await mutationFn(input);

            setData(responseData);
            options && options.onSuccess && (await options.onSuccess(responseData));
            onSuccess && (await onSuccess(responseData));
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error) || error.message;
            setError && setError(errorText);

            onError && (await onError(error, errorText));
            options && options.onError && (await options.onError(error, errorText));
        }

        setIsLoading(false);
    };

    return {
        mutate,
        isLoading,
        error,
        data
    };
};

export default useMutation;
