import React, { useMemo } from 'react';
import { Box } from 'rebass';
import { Input, Label, Select } from '@rebass/forms';
import { FiCalendar } from 'react-icons/fi';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import helpers from 'utils/helpers';
import TextAreaAutosize from 'react-textarea-autosize';
import NiceSelect from 'components/NiceSelect';

/**
 * Display a dropdown with participant options.
 *
 * @param {{ label: string, value: string }[]} options
 * @param {string} value
 * @param {({ target: { name: string, value: string } }) => void} onChange
 * @param {string} name
 */
const ParticipantDropdownInput = ({ options, value, onChange, name }) => {
    const items = useMemo(() => {
        return [{ id: '', title: 'None' }, ...options.map(option => ({ id: option.value, title: option.label }))];
    }, [options]);
    const mapValueToLabel = useMemo(() => {
        return options.reduce(
            (acc, option) => {
                acc[option.value] = option.label;
                return acc;
            },
            { '': 'None' }
        );
    }, [items]);

    return (
        <NiceSelect
            showSearch
            mimicSelectStyle
            defaultValue={value}
            items={items}
            onChange={value => onChange({ target: { name, value } })}
            mapValueToLabel={mapValueToLabel}
        />
    );
};

function ParticipantInputElement({ cdk, cdv, onChange, onSave, onCancel }) {
    let inputElement = '';

    /**
     * Handle keydown events for the input element
     * If the user presses enter, save the value.
     * If the user presses escape, cancel the edit.
     */
    const onKeyDown = event => {
        if (event.key === 'Enter' && onSave) {
            return onSave(event);
        }

        if (event.key === 'Escape' && onCancel) {
            return onCancel(event);
        }
    };

    if (!helpers.isEditableAttribute(cdk.title)) {
        if (cdv && cdv.value) {
            inputElement = <div className="text-primary">{moment(cdv.value).format('LL')}</div>;
        } else {
            inputElement = <div>Field is not editable</div>;
        }
    } else if (cdk.type == 'date') {
        let selected_date = null;
        if (cdv && cdv.value) {
            const mom = moment(cdv.value);
            if (mom.isValid()) {
                selected_date = mom.toDate();
            }
        }

        inputElement = (
            <Box sx={{ position: 'relative' }} className="react-datepicker-w100p">
                <DatePicker
                    className="theme-input input-darker input-with-icon"
                    name={cdk.id}
                    id={`datepicker-${cdk.id}`}
                    selected={selected_date}
                    onKeyDown={onKeyDown}
                    onChange={date => {
                        onChange({ target: { name: cdk.id, value: date } });
                    }}
                />
                <Label for={`datepicker-${cdk.id}`} sx={{ position: 'absolute', left: '8px', top: '4px' }}>
                    <FiCalendar />
                </Label>
            </Box>
        );
    } else if (cdk.type == 'boolean') {
        inputElement = (
            <Select onChange={onChange} name={cdk.id} value={cdv ? cdv.value : ''}>
                <option></option>
                <option value="1">True</option>
                <option value="0">False</option>
            </Select>
        );
    } else if (cdk.type == 'dropdown') {
        inputElement = (
            <ParticipantDropdownInput
                onChange={onChange}
                name={cdk.id}
                value={cdv ? cdv.value : ''}
                options={cdk.definition}
            />
        );
    } else if (cdk.type == 'checkbox') {
        // convert value to array if so
        let checkbox_selected_values = [];
        //console.log('??', cdv);
        if (cdv.value) {
            if (Array.isArray(cdv.value)) {
                //console.log('is array ...')
                checkbox_selected_values = cdv.value;
            } else if (helpers.isJsonString(cdv.value)) {
                console.log('is json string...');
                checkbox_selected_values = JSON.parse(cdv.value);
            } else {
                console.log('NOT ARRAY OR JSON');
            }
        }
        console.log('checkbox_selected_values', checkbox_selected_values);

        let includesCustom = (checkbox_selected_values, def_item_value) => {
            let found = false;
            // turn into array if not an array
            if (!Array.isArray(checkbox_selected_values)) {
                checkbox_selected_values = Object.keys(checkbox_selected_values).map(key => {
                    return checkbox_selected_values[key];
                });
            }
            checkbox_selected_values.forEach(v => {
                if (v == def_item_value) {
                    found = true;
                }
            });
            return found;
        };

        let dd_options = cdk.definition.map((def_item, key) => {
            // cdv.value is an array
            // cdv.value.includes(def_item)
            let jsx_ret;
            try {
                jsx_ret = (
                    <Box key={key} fontSize={1}>
                        <label>
                            <input
                                type="checkbox"
                                name={cdk.id}
                                value={def_item.value}
                                checked={includesCustom(checkbox_selected_values, def_item.value)}
                                onChange={onChange}
                            />
                            &nbsp;{def_item.label}
                        </label>
                    </Box>
                );
            } catch (e) {
                helpers.trackError(e);
            }
            return jsx_ret;
        });

        inputElement = (
            <div style={{ maxHeight: '300px', overflowY: 'scroll', paddingRight: '16px' }}>{dd_options}</div>
        );
    } else if (cdk.type === 'text') {
        inputElement = (
            <TextAreaAutosize
                className="person_custom_data theme-input input-darker"
                onChange={onChange}
                name={cdk.id}
                value={cdv ? cdv.value : ''}
                type="text"
                style={{ width: '100%' }}
                minRows={2}
                placeholder="Your answer"
            />
        );
    } else {
        inputElement = (
            <Input
                type={cdk.type === 'number' ? 'number' : 'text'}
                className="person_custom_data"
                onKeyDown={onKeyDown}
                onChange={onChange}
                name={cdk.id}
                value={cdv ? cdv.value : ''}
            />
        );
    }

    return inputElement;
}

export default ParticipantInputElement;
