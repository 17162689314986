import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { Box } from 'rebass';

import { useWindowPositioner } from 'hooks/useWindowPositioner';

const WINDOW_BORDER_GAP = 32;

/**
 * Component to position a relative to a controller element.
 *
 * @param {Object} props
 * @param {Object} props.controllerRef Reference to the controller element
 * @param {ReactNode} props.children Content to be displayed
 * @param {Boolean} props.isVisible Flag to show or hide the content
 */
export function Positioner({ controllerRef, children, isVisible }) {
    const targetRef = useRef(null);
    const [coordinates, setCoordinates] = useState({ top: 0, left: 0 });
    const position = useWindowPositioner();

    useEffect(() => {
        setCoordinates(position(controllerRef, targetRef));
    }, [isVisible]);

    return (
        <Box
            ref={targetRef}
            style={{
                ...coordinates,
                position: 'absolute',
                visibility: isVisible ? 'visible' : 'hidden',
                height: window.innerHeight - coordinates.top - WINDOW_BORDER_GAP
            }}
        >
            {children}
        </Box>
    );
}

/**
 * Component to position a relative to the anchor element.
 *
 * @param {{ current: HTMLElement }} anchor Reference to the anchor element
 * @param {ReactNode} children Content to be displayed
 */
export function PositionerV2({ anchorRef, children }) {
    const position = useWindowPositioner();
    const containerRef = useRef(null);

    useLayoutEffect(() => {
        const container = containerRef.current;

        if (containerRef && anchorRef) {
            const coordinates = position(anchorRef, containerRef);

            const maxHeight = window.innerHeight + window.scrollY - coordinates.top - WINDOW_BORDER_GAP;

            // Apply the calculated positions
            container.style.height = 'max-content';
            container.style.maxHeight = `${maxHeight}px`;
            container.style.left = `${coordinates.left}px`;
            container.style.top = `${coordinates.top}px`;
        }
    }, []);

    return (
        <Box ref={containerRef} style={{ position: 'absolute' }}>
            {children}
        </Box>
    );
}

export default Positioner;
