import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { Flex, Box, Button } from 'rebass';
import { FiChevronDown } from 'react-icons/fi';

const ContentWrapper = styled.div`
    padding: 1px;
    overflow: ${props => (props.collapsed ? 'hidden' : 'visible')};
    transition: all 0.3s ease-in-out;
    max-height: ${props => (props.collapsed ? props.collapsedHeight + 'px' : 'none')};
    mask-image: ${props =>
        props.collapsed
            ? 'linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0.7) 70%, rgba(0, 0, 0, 0.1) 100%)'
            : 'unset'};
`;

function ShowMoreWrapper(props) {
    const ref = useRef();
    const [collapsed, setCollapsed] = useState(true);

    return (
        <Box>
            <ContentWrapper ref={ref} collapsed={collapsed} collapsedHeight={500}>
                {props.children}
            </ContentWrapper>
            {collapsed && (
                <Flex justifyContent="center">
                    <Button
                        variant="transparent"
                        onClick={() => setCollapsed(prevState => !prevState)}
                        fontWeight={600}
                    >
                        Show more{' '}
                        <FiChevronDown style={{ margin: '5px 0 0 0', fontSize: '14px', strokeWidth: '2.4px' }} />
                    </Button>
                </Flex>
            )}
        </Box>
    );
}

export default ShowMoreWrapper;
