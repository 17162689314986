import React from 'react';
import ID from './UUID';
import classNames from 'classnames';
import {
    FiPlus,
    FiPlusCircle,
    FiX,
    FiImage,
    FiTrash2,
    FiMove,
    FiFileText,
    FiArrowRightCircle,
    FiEdit,
    FiAlertCircle,
    FiArrowRight,
    FiZap
} from 'react-icons/fi';
import { Flex, Box, Button, Heading } from 'rebass';
import { Input, Select, Label, Textarea } from '@rebass/forms';
import ReactModal from 'react-modal';
import helpers from 'utils/helpers';
import helpersScreener from 'utils/helpers-screener';
import { withToastManager } from 'react-toast-notifications';
import { List, arrayMove } from 'react-movable';
import ReactTooltip from 'react-tooltip';
import { SCREENER_Q_DQ_REDIRECT_PREFIX, SCREENER_QDQS_REJECT, SCREENER_QDQS_ACCEPT } from 'utils/constants';
import TooltipIcon from 'components/TooltipIcon';
import NiceModal from 'components//NiceModal';
import ErrorLabel from 'components/ErrorLabel';
import { cloneDeep } from 'lodash';
import moment from 'moment-timezone';
import RichTextEditorModal from '../Shared/RichTextEditorModal';
import xss from 'xss';
import myxss from 'components/ReactFormBuilder/form-elements/myxss';
import PanelPropertyOptionsSelect from 'components/PanelPropertyOptionsSelect';

class DynamicOptionList extends React.Component {
    /**
     * @param {Object} props
     * @param {boolean} props.isSynced - whether the element is two-way synced with panel or not
     * @param {Object} props.isPopulable - whether the element is populable or not
     * @param {() => void} props.onPopulate - callback to populate the element
     */
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.element,
            data: this.props.data,
            dirty: false,
            showAddBulkOptions: false,
            activateOption: null,
            showModalWysiwyg: false
        };

        this.addOptionsBulk = this.addOptionsBulk.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
        this.handleRemoveOptions = this.handleRemoveOptions.bind(this);
    }

    _setValue(text) {
        return text.replace(/[^A-Z0-9]+/gi, '_').toLowerCase();
    }

    editOption(option_index, e) {
        const this_element = cloneDeep(this.state.element);
        this_element.options[option_index].text = e.target.value;

        // don't update the value if sync is enabled
        if (!this.props.isSynced) {
            this_element.options[option_index].value = this._setValue(e.target.value);
        }

        this.setState({
            element: this_element,
            dirty: true
        });
    }

    editValue(option_index, e) {
        const this_element = this.state.element;
        const val = e.target.value === '' ? this._setValue(this_element.options[option_index].text) : e.target.value;
        this_element.options[option_index].value = val;
        this.setState({
            element: this_element,
            dirty: true
        });
    }

    /**
     * Updates the option at the given index with the given value.
     * This function doesn't update the element text. only the option value.
     *
     * @param {number|string} optionIndex index of the option to update
     * @param {string} newValue new value of the option
     */
    async selectProperty(optionIndex, newValue) {
        // eslint-disable-next-line react/no-access-state-in-setstate
        const element = cloneDeep(this.state.element);
        element.options[optionIndex].value = newValue;

        // update internal and external state
        this.setState({
            element,
            dirty: false
        });
        this.props.updateElement.call(this.props.preview, element);
    }

    // eslint-disable-next-line no-unused-vars
    editOptionCorrect(option_index, e) {
        const this_element = this.state.element;
        if (this_element.options[option_index].hasOwnProperty('correct')) {
            delete this_element.options[option_index].correct;
        } else {
            this_element.options[option_index].correct = true;
        }
        this.setState({ element: this_element });
        this.props.updateElement.call(this.props.preview, this_element);
    }

    editOptionQualification(option_index, e) {
        const this_element = this.state.element;
        const optionValue = e.target.value;

        //console.log('has qualification prop', this_element.options[option_index].hasOwnProperty('qualification'));
        //console.log('qualification option value', optionValue);

        this_element.options[option_index].qualification = optionValue;

        //console.log('updating element', this_element);
        this.setState({ element: this_element });
        this.props.updateElement.call(this.props.preview, this_element);
    }

    editOptionType(option_index, e) {
        const this_element = this.state.element;
        const optionValue = e.target.value;

        this_element.options[option_index].type = optionValue;

        this.setState({ element: this_element });
        this.props.updateElement.call(this.props.preview, this_element);
    }

    updateOption() {
        const this_element = this.state.element;
        // to prevent ajax calls with no change
        if (this.state.dirty) {
            this.props.updateElement.call(this.props.preview, this_element);
            this.setState({ dirty: false });
        }
    }

    editOptionReportingValue(option_index, e) {
        const this_element = this.state.element;

        this_element.options[option_index].reporting_value = e.target.value;

        this.setState({
            element: this_element,
            dirty: true
        });
    }

    /**
     * Returns the panel property object for the given columnId.
     *
     * @param {any} columnId id of the panel property
     */
    getPanelProperty(columnId) {
        return this.props.availableColumns.find(item => item.id === columnId);
    }

    addOption(index, optionDefaults, skipXhrSave) {
        const this_element = this.state.element;
        let opt = {
            value: optionDefaults.value ? optionDefaults.value : '',
            text: optionDefaults.text ? optionDefaults.text : '',
            key: ID.uuid()
        };

        // Check if panel property is boolean or not
        const columnId = this.state.element.personCustomDataId;
        const panelProperty = this.getPanelProperty(columnId);
        if (panelProperty && panelProperty.type === 'boolean') {
            opt.value = 0;
        }

        //console.log('add option index', index, this_element.options)
        if ('Matrix' == this.state.element.element) {
            const has_columns = this_element.options.filter(o => o && o.type && o.type == 'column');

            //console.log('...adding', has_columns, opt.type)

            if (has_columns && has_columns.length) {
                opt.type = 'column';
            } else {
                opt.type = 'row';
            }
        }

        this_element.options.splice(index + 1, 0, opt);

        if (!skipXhrSave) {
            this.props.updateElement.call(this.props.preview, this_element);
        }
    }

    removeOption(index, skipXhrSave) {
        //console.log('deleting', index, skipXhrSave)
        const this_element = this.state.element;
        this_element.options.splice(index, 1);

        if (skipXhrSave) {
            //console.log('skipping delete save')
        } else {
            this.props.updateElement.call(this.props.preview, this_element);
        }
    }

    rearrangeOptions(oldIndex, newIndex) {
        const this_element = cloneDeep(this.state.element);

        this_element.options = arrayMove(this_element.options, oldIndex, newIndex);

        this.props.updateElement.call(this.props.preview, this_element);
    }

    addOptionsBulk() {
        this.setState({ showAddBulkOptions: false });

        const that = this;
        let new_options = [];

        if (this.state.addBulkOptionsValue) {
            let arr = this.state.addBulkOptionsValue.split(/\r?\n/);
            if (arr.length) {
                arr.forEach(text_line => {
                    text_line = text_line.trim();
                    if (text_line && text_line.length > 0) {
                        new_options.push({
                            text: text_line,
                            value: text_line
                        });
                    }
                });
            }
        }

        if (new_options) {
            const skipXhrSave = true;
            const this_element = this.state.element;
            //console.log("OPTS", this_element.options)
            this_element.options.removeIf(o => o && o.text == '');

            new_options.forEach((new_option, key) => {
                that.addOption(that.state.element.options.length - 1, new_option, skipXhrSave);
            });

            this.props.updateElement.call(this.props.preview, this_element);
        }
    }

    componentDidCatch(error, errorInfo) {
        helpers.trackError(error);
    }

    componentWillReceiveProps(nextProps) {
        let this_element = cloneDeep(nextProps.element);
        if (this.state.dirty === false) this.setState({ element: this_element });
    }

    handleImageChange(error, value, option_index) {
        if (error) {
            alert(body);
            return;
        }

        // if "value" is empty we are deleting the image
        if (value) {
            const { toastManager } = this.props;
            toastManager.add('Successfully uploaded image', {
                appearance: 'success',
                autoDismiss: true
            });
        }

        const this_element = this.state.element;

        this_element.options[option_index].image = value;

        this.setState({ element: this_element }, () => {
            this.props.updateElement.call(this.props.preview, this_element);
        });
    }

    handleImageUploadClick(option, option_index) {
        if (option.image) {
            helpersScreener.imageRemove(option.image, err => {
                console.log(err);
            });
        }

        const callbackUploading = () => {
            const { toastManager } = this.props;
            toastManager.add('Image uploading...', {
                appearance: 'info',
                autoDismiss: true
            });
        };

        helpersScreener.imageUploadButton(`option-image-${option.key}`, callbackUploading, (error, image_url) => {
            this.handleImageChange(error, image_url, option_index);
        });
    }

    isNonStandardOption(option) {
        return option.is_add_other || option.is_none_of_the_above;
    }

    renderNonStandardOption(option, index) {
        let element;
        if (option.is_add_other) {
            element = (
                <Input
                    //tabIndex={index + 1}
                    type="text"
                    name={`text_${index}`}
                    placeholder={`"Other" answer option`}
                    value={option.text}
                    disabled={true}
                    //className="theme-input theme-input-simple"
                    className="theme-input"
                />
            );
        } else if (option.is_none_of_the_above) {
            element = (
                <Input
                    //tabIndex={index + 1}
                    type="text"
                    name={`text_${index}`}
                    placeholder={`"None of the above" answer option`}
                    value={option.text}
                    disabled={true}
                    //className="theme-input theme-input-simple"
                    className="theme-input"
                />
            );
        }

        return element;
    }

    shouldShowReportingValues(props) {
        return props.canHaveOptionValue && props.element.reporting_values;
    }

    handleRemoveOptions() {
        const this_element = this.state.element;
        this_element.options = [];
        this.setState({ element: this_element });
        this.props.updateElement.call(this.props.preview, this_element);
    }

    /**
     * Returns the label of the piped question.
     *
     * @param {string} [fieldName] field_name of the piped question
     * @returns {string} label of the piped question
     */
    getPipedQuestionLabel(fieldName) {
        const errorMessage = 'Piped question does not exist';

        // if no field_name provided, return error message
        if (!fieldName) {
            return errorMessage;
        }

        const pipedQuestion = this.props.data.reduce((acc, pageQuestions, pageIndex) => {
            const questionIndex = pageQuestions.findIndex(pageQuestion => pageQuestion.field_name === fieldName);
            if (questionIndex !== -1) {
                return {
                    pageNumber: pageIndex + 1,
                    questionNumber: questionIndex + 1,
                    question: pageQuestions[questionIndex]
                };
            }
            return acc;
        }, null);

        // If flied name is provided and no question found, remove error message
        if (!pipedQuestion) {
            return errorMessage;
        }

        return `(P${pipedQuestion.pageNumber}Q${pipedQuestion.questionNumber}) ${pipedQuestion.question.label}`;
    }

    getQuestionText(id) {
        return this.props.element.options[id].text;
    }

    editElementProp(targProperty, e) {
        const this_element = cloneDeep(this.state.element);
        const new_value = helpers.containsHtml(e.target[targProperty])
            ? xss(e.target[targProperty])
            : e.target[targProperty];
        this_element.options[this.state.activateOption].text = new_value;

        this.setState(
            {
                element: this_element,
                dirty: true
            },
            () => {
                this.updateElement();
            }
        );
    }

    updateElement() {
        // to prevent ajax calls with no change
        if (this.state.dirty && this.props.updateElement) {
            const this_element = cloneDeep(this.state.element);
            this.props.updateElement(this_element);
            this.setState({ dirty: false });
        }
    }

    displayOptionErrors(key) {
        const errors = [];

        // two-way sync option - property mismatch
        if (
            ['RadioButtons', 'Checkboxes', 'Dropdown'].includes(this.state.element.element) &&
            this.state.element.save_to_panel
        ) {
            const personCustomDataId = this.state.element.personCustomDataId;
            const optionToCompare = this.state.element.options.find(item => item.key === key);
            // eslint-disable-next-line eqeqeq
            const foundPanelProperty = this.props.availableColumns.find(item => item.id == personCustomDataId); // compare with == because personCustomDataId is a string

            if (foundPanelProperty) {
                if (foundPanelProperty.type === 'boolean') {
                    if (!helpers.isStringMappedToBoolean(optionToCompare.value)) {
                        errors.push(
                            <>
                                Option does not match any boolean value although it's{' '}
                                <a href="https://docs.panelfox.io/article/74-two-way-sync" target="_blank">
                                    Two-way synced
                                </a>{' '}
                                with a boolean Panel Property. Will not save properly to panelist profile. Correct
                                values are: <strong>True</strong>, <strong>False</strong>, <strong>Yes</strong>,{' '}
                                <strong>No</strong>, <strong>1</strong> or <strong>0</strong>.
                            </>
                        );
                    }
                } else {
                    if (
                        foundPanelProperty.definition &&
                        foundPanelProperty.definition.findIndex(ppItem => ppItem.value === optionToCompare.value) === -1
                    ) {
                        errors.push(
                            <>
                                Option does not match any Panel Properties in{' '}
                                <a href="https://docs.panelfox.io/article/74-two-way-sync" target="_blank">
                                    Two-way sync
                                </a>
                                . Will not save properly to panelist profile.
                            </>
                        );
                    }
                }
            }
        }

        if (errors.length) {
            return errors.map(error => {
                return (
                    <Flex alignItems="start" mt="8px">
                        <FiAlertCircle
                            style={{
                                marginRight: '4px',
                                marginTop: '2px',
                                fontSize: '14px',
                                minWidth: '14px',
                                strokeWidth: '2px',
                                color: '#D42220'
                            }}
                        />
                        <Box className="fs-accent-12 color-danger">{error}</Box>
                    </Flex>
                );
            });
        } else {
            return null;
        }
    }

    render() {
        const all_pages = this.state.data;
        const num_pages = all_pages.length;
        let additional_qual_disqual_redirects = [];

        let q_on_page_number;
        let page_number;
        for (let page_index = 0; page_index < num_pages; page_index++) {
            page_number = page_index + 1;
            // find which page this element is on
            let q_found = all_pages[page_index].find(q => q.id == this.state.element.id);
            if (q_found) {
                q_on_page_number = page_number;
            }

            // only allow redirect to pages after the page current element was found on
            if (page_number > q_on_page_number) {
                additional_qual_disqual_redirects.push(
                    <option value={`${SCREENER_Q_DQ_REDIRECT_PREFIX}${page_number}`}>Skip to page {page_number}</option>
                );
            }
        }

        const this_element = this.state.element;
        //console.log('DOL', this.state)
        if (this.state.dirty) {
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.element.dirty = true;
        }

        let isMatrixAndMissingColumnOption = false;

        const options = this.state.element.options.map(option => {
            return {
                ...option,
                error: this.isNonStandardOption(option)
                    ? this.displayOptionErrors(option.key)
                    : this.displayOptionErrors(option.key)
            };
        });

        if ('Matrix' === this.state.element.element) {
            isMatrixAndMissingColumnOption = true;
            options.forEach(o => {
                if (o && o.type === 'column') {
                    isMatrixAndMissingColumnOption = false;
                }
            });
        }

        // Display panel property column only if the element is synced with panel and is not a Matrix
        const isPanelPropertyColumn = this.props.isSynced && !['Matrix'].includes(this.state.element.element);

        return (
            <>
                <div className="editor-options-container">
                    <List
                        values={options}
                        onChange={({ oldIndex, newIndex }) => {
                            this.rearrangeOptions(oldIndex, newIndex);
                        }}
                        renderList={({ children, props }) => (
                            <Box
                                as="table"
                                mt={3}
                                sx={{
                                    width: '100%'
                                }}
                            >
                                <thead>
                                    <tr>
                                        <Box as="th">
                                            <Flex sx={{ gap: '8px' }}>
                                                <Label sx={{ whiteSpace: 'nowrap' }} className="text-primary medium">
                                                    Options{' '}
                                                    {options.length <= 0 && (
                                                        <Box as="span" ml={2} className="text-warn">
                                                            Add answer options to allow respondents to answer the
                                                            question.
                                                        </Box>
                                                    )}
                                                </Label>
                                                {isMatrixAndMissingColumnOption == true && (
                                                    <Box fontSize={1}>
                                                        <span
                                                            className={'bg-error rounded red fs-13'}
                                                            style={{ padding: '4px 8px' }}
                                                        >
                                                            Warning: At least 1 option must be a Column.
                                                        </span>
                                                    </Box>
                                                )}
                                            </Flex>
                                        </Box>
                                        {isPanelPropertyColumn && (
                                            <Box as="th">
                                                <Label className="text-primary medium" sx={{ paddingLeft: '24px' }}>
                                                    Panel Property
                                                    <TooltipIcon for={`tooltip-panel_property`} margin="2px 0 0 8px" />
                                                </Label>
                                            </Box>
                                        )}
                                        {this.shouldShowReportingValues(this.props) &&
                                            ['RadioButtons', 'Dropdown', 'Checkboxes', 'Matrix'].includes(
                                                this.state.element.element
                                            ) && (
                                                <Box as="th">
                                                    <Label className="text-primary medium">
                                                        Reporting Value
                                                        <TooltipIcon
                                                            for={`tooltip-reporting_value`}
                                                            margin="2px 0 0 8px"
                                                        />
                                                    </Label>
                                                </Box>
                                            )}
                                        {'Matrix' == this.state.element.element && <Box as="th"></Box>}
                                        {this.props.canHaveOptionValue &&
                                            this.props.element.qual_disqual &&
                                            ['RadioButtons', 'Dropdown'].includes(this.state.element.element) && (
                                                <Box px={2} as="th">
                                                    <Label className="text-primary medium">Q / DQ / Skip</Label>
                                                </Box>
                                            )}
                                        {this.props.canHaveOptionValue &&
                                            this.props.element.qual_disqual &&
                                            ['Checkboxes'].includes(this.state.element.element) && (
                                                <Box px={2} as="th">
                                                    <Label className="text-primary medium">Qualification</Label>
                                                </Box>
                                            )}
                                        <Box as="th"></Box>
                                    </tr>
                                </thead>
                                <tbody {...props}>{children}</tbody>
                            </Box>
                        )}
                        renderItem={({ value, index, props: { style, ...props }, isDragged }) => {
                            let option = value;
                            /*return <li {...props}>
                                <Button
                                    data-movable-handle
                                    type='button'
                                    variant='link'
                                    className='muted'
                                    tabIndex={-1}
                                    style={{
                                        cursor: isDragged ? 'grabbing' : 'grab',
                                    }}
                                >
                                    <FiMove />
                                </Button>
                                {value.value}
                            </li>;*/
                            const this_key = `edit_${option.key}`;
                            const val = option.value !== this._setValue(option.text) ? option.value : '';

                            const row = (
                                <>
                                    <Box
                                        as="tr"
                                        {...props}
                                        key={this_key}
                                        // This is very important for displaying dropdowns correctly.
                                        // There used to be a problem with other things blocking the dropdowns
                                        // because they were set to relative and were higher up in the tree.
                                        // By changing it to static when the item is not dragged, all dropdown items do not conflict.
                                        // Please change with care.
                                        style={{ ...style, position: isDragged ? 'fixed' : 'static' }}
                                    >
                                        <Box px={1} as="td" py={2} width="100%" minWidth="160px" verticalAlign="top">
                                            {this.isNonStandardOption(option) ? (
                                                <>
                                                    {this.renderNonStandardOption(option, index)}
                                                    {option.error && <Box px={1}>{option.error}</Box>}
                                                </>
                                            ) : (
                                                <>
                                                    <Flex>
                                                        {helpers.containsHtml(option.text) ? (
                                                            <Box className="quill-render">
                                                                <div
                                                                    style={{ whiteSpace: 'pre-wrap' }}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: myxss.process(option.text)
                                                                    }}
                                                                />
                                                            </Box>
                                                        ) : (
                                                            <Input
                                                                type="text"
                                                                name={`text_${index}`}
                                                                placeholder="Option text"
                                                                value={option.text}
                                                                onBlur={this.updateOption.bind(this)}
                                                                onChange={this.editOption.bind(this, index)}
                                                                className={classNames(
                                                                    'theme-input',
                                                                    option.error && 'shadow-solid-danger--important'
                                                                )}
                                                            />
                                                        )}
                                                    </Flex>
                                                    {['RadioButtons', 'Checkboxes'].includes(
                                                        this.state.element.element
                                                    ) &&
                                                        option.image && (
                                                            <Box mt={2} mb={2}>
                                                                <Button
                                                                    mb={2}
                                                                    type="button"
                                                                    variant="secondary-gray"
                                                                    tabIndex="-1"
                                                                    //onClick={()=> { if(confirm('Are you sure you want to delete this image?')) { this.handleImageChange('', index); }}}
                                                                    onClick={() => {
                                                                        if (
                                                                            confirm(
                                                                                'Are you sure you want to delete this image?'
                                                                            )
                                                                        ) {
                                                                            helpersScreener.imageRemove(
                                                                                option.image,
                                                                                err => {
                                                                                    console.log(err);
                                                                                }
                                                                            );
                                                                            this.handleImageChange(false, '', index);
                                                                        }
                                                                    }}
                                                                >
                                                                    <FiTrash2 /> Delete Image
                                                                </Button>
                                                                <br />

                                                                <img src={option.image} style={{ maxWidth: '160px' }} />
                                                            </Box>
                                                        )}
                                                    {option.error && <Box px={1}>{option.error}</Box>}
                                                </>
                                            )}
                                        </Box>
                                        {isPanelPropertyColumn && (
                                            <Box as="td" width="300px" minWidth="300px" py={2} verticalAlign="top">
                                                <Flex alignItems="center" width="100%">
                                                    <FiArrowRight style={{ fontSize: '16px', marginRight: '8px' }} />
                                                    <PanelPropertyOptionsSelect
                                                        css={{ flexGrow: 1 }}
                                                        columns={this.props.availableColumns}
                                                        columnId={this.state.element.personCustomDataId}
                                                        value={option.value}
                                                        onChange={value => this.selectProperty(index, value)}
                                                    />
                                                </Flex>
                                            </Box>
                                        )}
                                        {this.shouldShowReportingValues(this.props) &&
                                            ['RadioButtons', 'Dropdown', 'Checkboxes', 'Matrix'].includes(
                                                this.state.element.element
                                            ) && (
                                                <Box as="td" py={2} verticalAlign="top" minWidth="200px" width="200px">
                                                    {!this.isNonStandardOption(option) && (
                                                        <Input
                                                            //tabIndex={index + 1}
                                                            type="text"
                                                            name={`reporting_value_${index}`}
                                                            placeholder=""
                                                            value={option.reporting_value}
                                                            onBlur={this.updateOption.bind(this)}
                                                            onChange={this.editOptionReportingValue.bind(this, index)}
                                                            //className="theme-input theme-input-simple"
                                                            className="theme-input"
                                                        />
                                                    )}
                                                </Box>
                                            )}
                                        {'Matrix' == this.state.element.element && (
                                            <Box as="td" py={2} verticalAlign="top" minWidth="200px" width="200px">
                                                <Select
                                                    ml={1}
                                                    mr={2}
                                                    tabIndex={-1}
                                                    onChange={this.editOptionType.bind(this, index)}
                                                    value={option.hasOwnProperty('type') ? option.type : ''}
                                                    //className='theme-input theme-input-simple'
                                                    className="theme-input"
                                                >
                                                    <option value=""></option>
                                                    <option value="row">Row</option>
                                                    <option value="column">Column</option>
                                                </Select>
                                            </Box>
                                        )}
                                        {this.props.canHaveOptionValue &&
                                            this.props.element.qual_disqual &&
                                            ['RadioButtons', 'Dropdown'].includes(this.state.element.element) && (
                                                <Box
                                                    px={2}
                                                    as="td"
                                                    py={2}
                                                    verticalAlign="top"
                                                    minWidth="200px"
                                                    width="200px"
                                                >
                                                    <Select
                                                        onChange={this.editOptionQualification.bind(this, index)}
                                                        value={
                                                            option.hasOwnProperty('qualification')
                                                                ? option.qualification
                                                                : ''
                                                        }
                                                        //className='theme-input theme-input-simple'
                                                        className="theme-input"
                                                    >
                                                        <option value=""></option>
                                                        <option value={SCREENER_QDQS_ACCEPT}>Accept</option>
                                                        <option value={SCREENER_QDQS_REJECT}>Disqualify</option>
                                                        {additional_qual_disqual_redirects}
                                                    </Select>
                                                </Box>
                                            )}
                                        {this.props.canHaveOptionValue &&
                                            this.props.element.qual_disqual &&
                                            ['Checkboxes'].includes(this.state.element.element) && (
                                                <Box
                                                    px={2}
                                                    as="td"
                                                    py={2}
                                                    verticalAlign="top"
                                                    minWidth="200px"
                                                    width="200px"
                                                >
                                                    <Select
                                                        onChange={this.editOptionQualification.bind(this, index)}
                                                        value={
                                                            option.hasOwnProperty('qualification')
                                                                ? option.qualification
                                                                : ''
                                                        }
                                                        //className='theme-input theme-input-simple'
                                                        className="theme-input"
                                                    >
                                                        <option value=""></option>
                                                        <option value="Must Select">Must Select</option>
                                                        <option value="May select">May Select</option>
                                                        <option value={SCREENER_QDQS_REJECT}>Disqualify</option>
                                                        {additional_qual_disqual_redirects}
                                                    </Select>
                                                </Box>
                                            )}
                                        <Box
                                            pl={1}
                                            as="td"
                                            py={2}
                                            verticalAlign="top"
                                            minWidth="max-content"
                                            width="max-content"
                                        >
                                            {!this.isNonStandardOption(option) && (
                                                <Box>
                                                    <Flex flexWrap="nowrap" className="dynamic-options-actions-buttons">
                                                        {['RadioButtons', 'Checkboxes'].includes(
                                                            this.state.element.element
                                                        ) && (
                                                            <>
                                                                {/* Option Rich Text */}
                                                                <Button
                                                                    type="button"
                                                                    variant="secondary-gray"
                                                                    className="secondary-icon"
                                                                    tabIndex={-1}
                                                                    mr={2}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            activateOption: index,
                                                                            showModalWysiwyg: true,
                                                                            modalWysiwygHtml: this.getQuestionText(
                                                                                index
                                                                            )
                                                                        });
                                                                    }}
                                                                >
                                                                    <FiEdit />
                                                                </Button>
                                                                <Button
                                                                    type="button"
                                                                    variant="secondary-gray"
                                                                    className="secondary-icon"
                                                                    mr={2}
                                                                    //onClick={(e) => { const url = prompt('Paste image URL:'); if (url) { this.handleImageChange(url, index); }}}
                                                                    onClick={e => {
                                                                        this.handleImageUploadClick(option, index);
                                                                    }}
                                                                    tabIndex="-1"
                                                                >
                                                                    <FiImage />
                                                                </Button>
                                                                <input
                                                                    type="file"
                                                                    id={`option-image-${option.key}`}
                                                                    accept="image/x-png,image/jpeg"
                                                                    hidden
                                                                />
                                                            </>
                                                        )}

                                                        <Button
                                                            data-movable-handle
                                                            type="button"
                                                            variant="secondary-gray"
                                                            className="secondary-icon"
                                                            tabIndex={-1}
                                                            style={{
                                                                cursor: isDragged ? 'grabbing' : 'grab'
                                                            }}
                                                            mr={2}
                                                        >
                                                            <FiMove />
                                                        </Button>
                                                        {/*<Button
                                                                style={{margin:'0 8px 0 16px', fontSize: '18px'}}
                                                                variant='link'
                                                                onClick={this.addOption.bind(this, index)}
                                                                tabIndex="-1"
                                                            >
                                                                <FiPlusCircle />
                                                            </Button>*/}
                                                        {/*{index > 0 && (*/}
                                                        <Button
                                                            variant="secondary-gray"
                                                            className="secondary-icon"
                                                            onClick={this.removeOption.bind(this, index, false)}
                                                            tabIndex="-1"
                                                        >
                                                            <FiX />
                                                        </Button>
                                                        {/*)}*/}
                                                    </Flex>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                </>
                            );

                            return isDragged ? (
                                <table style={{ ...style, width: '100%', borderSpacing: 0 }}>
                                    <tbody>{row}</tbody>
                                </table>
                            ) : (
                                row
                            );
                        }}
                    />
                    {this.state.element.pipe_options_from ? (
                        <Flex sx={{ margin: '-8px 0 4px 0px' }}>
                            <Box className="text-secondary va-top fs-13" sx={{ paddingTop: '6px' }}>
                                <FiArrowRightCircle
                                    style={{ fontSize: '15px', verticalAlign: 'top', margin: '3px 6px 0 0' }}
                                />
                                Piping in answers from{' '}
                                <span
                                    className="ellipsis medium text-primary va-top"
                                    style={{ maxWidth: '300px', display: 'inline-block' }}
                                >
                                    {helpers.removeHtml(
                                        this.getPipedQuestionLabel(this.state.element.pipe_options_from)
                                    )}
                                </span>
                            </Box>
                            <Button
                                variant="link"
                                type="button"
                                className="fs-13 red"
                                ml={3}
                                onClick={() => this.props.onClickRemovePiping()}
                            >
                                Remove piping
                            </Button>
                        </Flex>
                    ) : (
                        ''
                    )}
                    <Flex alignItems="flex-start" mb={2}>
                        <Flex sx={{ rowGap: 1, columnGap: 3 }} flexGrow={1} flexWrap="wrap" mr={3}>
                            {this.props.isSynced && this.props.isPopulable && (
                                <Button variant="link" onClick={this.props.onPopulate}>
                                    <FiZap /> Populate answer options from this property
                                </Button>
                            )}

                            <Button
                                variant="link"
                                className={classNames('fs-13', this.props.isSynced && 'text-pure')}
                                onClick={this.addOption.bind(this, this.state.element.options.length)}
                                tabIndex="-1"
                            >
                                <FiPlus style={{ marginTop: '3px' }} /> Add option
                            </Button>

                            <Button
                                variant="link"
                                className={classNames('fs-13', this.props.isSynced && 'text-pure')}
                                onClick={() => this.setState({ showAddBulkOptions: true })}
                                tabIndex="-1"
                            >
                                <FiPlus style={{ marginTop: '3px' }} /> Add bulk options
                            </Button>

                            {this.state.element.pipe_options_from ? (
                                ''
                            ) : (
                                <Button
                                    variant="link"
                                    className={classNames('fs-13', this.props.isSynced && 'text-pure')}
                                    onClick={() => this.props.onClickAddPiping()}
                                    tabIndex="-1"
                                >
                                    <FiPlus style={{ marginTop: '3px' }} /> Add piping options
                                </Button>
                            )}
                        </Flex>

                        {this.state.element.options.length > 0 && (
                            <Button
                                variant="link"
                                className="red fs-13"
                                minWidth="max-content"
                                onClick={() => {
                                    if (confirm('Are you sure you want to remove all options?')) {
                                        this.handleRemoveOptions();
                                    }
                                }}
                                tabIndex="-1"
                            >
                                Remove all
                            </Button>
                        )}
                    </Flex>
                </div>
                <RichTextEditorModal
                    isOpen={this.state.showModalWysiwyg}
                    onClose={() => this.setState({ showModalWysiwyg: false })}
                    modalTitle="Rich Text Editor"
                    editorValue={this.state.modalWysiwygHtml}
                    onChangeEditor={html => {
                        this.setState({ modalWysiwygHtml: html });
                    }}
                    actionCancelLabel="Cancel"
                    actionConfirmLabel="Save"
                    actionCancel={() => this.setState({ showModalWysiwyg: false })}
                    actionConfirm={() => {
                        const { modalWysiwygHtml } = this.state;
                        const forceSave = true;
                        this.editElementProp('value', { target: { value: modalWysiwygHtml } });
                        this.setState({ showModalWysiwyg: false });
                    }}
                    ref={this.quillRef}
                />
                <ReactModal
                    isOpen={this.state.showAddBulkOptions}
                    shouldCloseOnOverlayClick
                    onRequestClose={() => {
                        this.setState({ showAddBulkOptions: false });
                    }}
                >
                    <Box m={4}>
                        <Heading mb={3}>Add bulk options</Heading>
                        <Textarea
                            placeholder="Add each option on a new line"
                            value={this.state.addBulkOptionsValue}
                            onChange={e => this.setState({ addBulkOptionsValue: e.target.value })}
                            sx={{ minHeight: '200px' }}
                            className="theme-input"
                            autoFocus
                        ></Textarea>

                        <Box mt={3} style={{ textAlign: 'right' }}>
                            <Button
                                variant="tertiary"
                                type="button"
                                mr={2}
                                onClick={() => {
                                    this.setState({ showAddBulkOptions: false });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button type="button" variant="primary" mr={0} onClick={this.addOptionsBulk}>
                                Add options
                            </Button>
                        </Box>
                    </Box>
                </ReactModal>

                <ReactTooltip id={`tooltip-panel_property`} effect="solid" place="top">
                    The panel property this option should sync to.
                </ReactTooltip>
                <ReactTooltip id={`tooltip-reporting_value`} effect="solid" place="top">
                    Setting a Reporting Value displays the answer
                    <br /> option as a "code" in the screener export file.
                </ReactTooltip>
            </>
        );
    }
}

export default withToastManager(DynamicOptionList);

{
    /*this.props.canHaveOptionValue && (
    <Box px={2} width={1/2}>
        <Input
            type="text"
            name={`value_${index}`}
            value={val}
            onChange={this.editValue.bind(this, index)}
        />
    </Box>
)*/
}
{
    /* this.props.canHaveOptionValue && this.props.canHaveOptionCorrect &&
<div className="col-sm-1">
  <Input className="form-control" type="checkbox" value="1" onChange={this.editOptionCorrect.bind(this, index)} checked={option.hasOwnProperty('correct')} />
</div> */
}
